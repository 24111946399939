import { RootObject } from '@/model/rootObject'
import { UserInfoModel } from '@/model/userModel'
import https from '@/utils/https'
import { LoginModel } from '@/views/user-manager/login/model/loginModel'
import { RequestParams, ContentType, Method } from 'axios-mapper'
import { ModelList } from '@/model/modelList'

export const loginRequest = (userInfo: RequestParams) => {
  return https(false).request<RootObject<LoginModel>>('User/login', Method.POST, userInfo, ContentType.json)
}

export const userInfoRequest = () => {
  return https().request<RootObject<UserInfoModel>>('User/userInfo', Method.GET, undefined, ContentType.form)
}

export const getUsers = (params: any) => {
  return https().request<RootObject<ModelList<UserInfoModel>>>('User/items', Method.POST, params, ContentType.json)
}

export const update = (id: number, params: any) => {
  return https().request<RootObject<UserInfoModel>>(`User/${id}`, Method.PUT, params, ContentType.json)
}
